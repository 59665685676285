export const currencyToSignMap = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  AUD: '$A',
  CAD: '$C'
};

export const currencyToFullSignMap = {
  USD: {label: '$', size: 'big'},
  EUR: {label: '€', size: 'big'},
  GBP: {label: '£', size: 'big'},
  AUD: {label: '$AUD', size: 'small'},
  CAD: {label: '$CAD', size: 'small'}
};
